/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link, navigate } from "gatsby"
import { Location } from "@reach/router"

import useWindowSize from "../../hooks/useWindowSize"
import usePrevious from "../../hooks/usePrevious"
import { isLoggedIn, logout } from "../../services/auth"
import GlobalContext from "../../context/global-context"
import logo from "../../images/gatsby-icon.png"
import { Spinner, MenuButton, Close } from "@theme-ui/components"

const SCREEN_MOBILE_WIDTH = 639

const Header = props => {
  const size = useWindowSize()
  const prevSize = usePrevious(size.width)
  // default to desktop
  const [isMobile, setIsMobile] = useState(false)
  const [expandedMobile, setExpandedMobile] = useState(false)

  useEffect(() => {
    if (size.width <= SCREEN_MOBILE_WIDTH) {
      setupMobile()
    }
  }, [])

  useEffect(() => {
    // checks
    const screenToMobile =
      prevSize > SCREEN_MOBILE_WIDTH && size.width <= SCREEN_MOBILE_WIDTH
    const screenFromMobile =
      prevSize <= SCREEN_MOBILE_WIDTH && size.width > SCREEN_MOBILE_WIDTH

    if (screenToMobile) {
      setupMobile()
    } else if (screenFromMobile) {
      setupDesktop()
    }
  }, [size])

  const setupMobile = () => {
    setExpandedMobile(false)
    setIsMobile(true)
  }

  const setupDesktop = () => {
    setIsMobile(false)
    setExpandedMobile(false)
  }

  const handleExpandMobile = () => {
    setExpandedMobile(expanded => !expanded)
  }

  return (
    <GlobalContext.Consumer>
      {({ loading }) => (
        <header sx={{ variant: "styles.header" }}>
          <div sx={{ variant: "styles.headerContent" }}>
            {/* Logo & App name */}
            <Link
              to="/"
              sx={{
                variant: "styles.homeNavLink",
                // flexGrow: 1,
                // flexBasis: "sidebar",
                whiteSpace: "nowrap",
                // textAlign: ["center", "initial", "initial", "initial"],
              }}
            >
              {loading ? (
                <Spinner size={35} sx={{ verticalAlign: "text-bottom" }} />
              ) : (
                <img
                  src={logo}
                  alt="Logo"
                  sx={{
                    verticalAlign: "text-bottom",
                    width: [26, 35, 35, 35],
                  }}
                />
              )}

              <span sx={{ variant: "styles.homeNavLinkText" }}>
                {props.siteTitle}
              </span>
            </Link>

            {/* White space between */}
            <div sx={{ mx: "auto" }} />

            {/* Nav links */}
            {isMobile && (
              <div
                sx={{
                  display: ["inherit", "none", "none", "none"],
                }}
              >
                {expandedMobile ? (
                  <Close onClick={() => handleExpandMobile()} />
                ) : (
                  <MenuButton
                    onClick={() => handleExpandMobile()}
                    aria-label="Toggle Menu"
                  />
                )}
              </div>
            )}

            {(!isMobile || (isMobile && expandedMobile)) && (
              <div
                sx={{
                  // flexGrow: 99999,
                  // flexBasis: 0,
                  display: ["flex", "inherit", "inherit", "inherit"],
                  justifyContent: [
                    "space-between",
                    "inherit",
                    "inherit",
                    "inherit",
                  ],
                  width: ["100%", "inherit", "inherit", "inherit"],
                }}
              >
                <Location>
                  {({ location }) =>
                    isLoggedIn() ? (
                      <>
                        <Link
                          to="/app/dashboard"
                          sx={{ variant: "styles.navLink" }}
                        >
                          Dashboard
                        </Link>
                        <Link
                          to="/app/profile"
                          sx={{ variant: "styles.navLink" }}
                        >
                          Profile
                        </Link>
                        <Link
                          to="/"
                          sx={{ variant: "styles.navLink" }}
                          onClick={event => {
                            event.preventDefault()
                            logout(() => navigate(`/app/login`))
                          }}
                        >
                          Logout
                        </Link>
                      </>
                    ) : (
                      <>
                        <Link
                          to="/activity"
                          sx={{
                            variant: location.pathname.includes("/activity")
                              ? "styles.navLinkActive"
                              : "styles.navLink",
                          }}
                        >
                          Activity
                        </Link>
                        <Link
                          to="/stories/smartcities"
                          sx={{
                            variant: location.pathname.includes("/stories/")
                              ? "styles.navLinkActive"
                              : "styles.navLink",
                          }}
                        >
                          Stories
                        </Link>
                        <Link
                          to="/"
                          // to="/metrics/total-data"
                          sx={{
                            variant: location.pathname.includes("/metrics/")
                              ? "styles.navLinkActive"
                              : "styles.navLink",
                          }}
                        >
                          Metrics
                        </Link>
                      </>
                    )
                  }
                </Location>
              </div>
            )}
          </div>
        </header>
      )}
    </GlobalContext.Consumer>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
