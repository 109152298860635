import React, { useEffect } from "react"
import PropTypes from "prop-types"
import DeckGL from "deck.gl"
import { useColorMode } from "theme-ui"
import {
  StaticMap,
  // FullscreenControl,
  // NavigationControl
} from "react-map-gl"

import {
  MAPBOX_TOKEN,
  MAPBOX_LIGHT_MAP,
  MAPBOX_DARK_MAP,
  // LIGHT_MINIMALIST,
  // DARK_MINIMALIST,
  INITIAL_VIEW_STATE,
} from "../../../utils/constants"

export default function DeckGLMap(props) {
  const {
    mapType,
    layers,
    layerFilter,
    onLoadMap,
    opacity,
    extendedInitialView,
    viewState,
    onViewStateChange,
    darkBase,
    lightBase,
    mapOptions,
  } = props
  const [colorMode] = useColorMode()

  useEffect(() => {
    function preventRightClick(event) {
      event.preventDefault()
    }

    document.addEventListener("contextmenu", preventRightClick)

    return () => {
      document.removeEventListener("contextmenu", preventRightClick)
    }
  })

  // const prepareInitialView = extendedInitialView => {
  //   return extendedInitialView
  //     ? {
  //         ...INITIAL_VIEW_STATE,
  //         ...extendedInitialView,
  //       }
  //     : INITIAL_VIEW_STATE
  // }

  const renderMap = () => {
    const baseMap = (
      <StaticMap
        key={`deckgl-map-static-${mapType}`}
        mapboxApiAccessToken={MAPBOX_TOKEN}
        // reuseMaps
        mapStyle={
          colorMode === "light"
            ? lightBase || MAPBOX_LIGHT_MAP
            : darkBase || MAPBOX_DARK_MAP
        }
        // mapOptions={mapOptions || {}}
        attributionControl={false}
        className={opacity ? opacity : ""}
        onLoad={() => onLoadMap()}
      />
    )

    if (viewState && onViewStateChange) {
      return (
        <DeckGL
          layers={layers}
          viewState={
            viewState ||
            (extendedInitialView
              ? {
                  ...INITIAL_VIEW_STATE,
                  ...extendedInitialView,
                }
              : INITIAL_VIEW_STATE)
          }
          onViewStateChange={state => onViewStateChange(state)}
          controller
        >
          {baseMap}
        </DeckGL>
      )
    } else {
      return (
        <DeckGL
          layers={layers}
          // initialViewState={prepareInitialView()}
          initialViewState={
            extendedInitialView
              ? {
                  ...INITIAL_VIEW_STATE,
                  ...extendedInitialView,
                }
              : INITIAL_VIEW_STATE
          }
          controller
        >
          {baseMap}
        </DeckGL>
      )
    }
  }

  return layers.length > 0 && renderMap()
}

// DeckGLMap.defaultProps = {
//   onViewStateChange: viewState => viewState,
// }

// DeckGLMap.propTypes = {
//   onViewStateChange: PropTypes.func,
// }
