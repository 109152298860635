/** @jsx jsx */
import React, { useState, useEffect } from "react"
import { jsx, useColorMode } from "theme-ui"
import { Sun, Moon } from "react-feather"
import { ChevronDown, ChevronUp } from "react-feather"

import useWindowSize from "../../hooks/useWindowSize"
import usePrevious from "../../hooks/usePrevious"

import logo from "../../images/logo.svg"
import logoWhite from "../../images/logo-white.svg"

const SCREEN_MOBILE_WIDTH = 639

const Footer = ({ companyUrl, companyName }) => {
  const [colorMode, setColorMode] = useColorMode()
  const size = useWindowSize()
  const prevSize = usePrevious(size.width)
  // default to desktop
  const [isMobile, setIsMobile] = useState(false)
  const [expandedMobile, setExpandedMobile] = useState(false)

  useEffect(() => {
    if (size.width <= SCREEN_MOBILE_WIDTH) {
      setupMobile()
    }
  }, [])

  useEffect(() => {
    // checks
    const screenToMobile =
      prevSize > SCREEN_MOBILE_WIDTH && size.width <= SCREEN_MOBILE_WIDTH
    const screenFromMobile =
      prevSize <= SCREEN_MOBILE_WIDTH && size.width > SCREEN_MOBILE_WIDTH

    if (screenToMobile) {
      setupMobile()
    } else if (screenFromMobile) {
      setupDesktop()
    }
  }, [size])

  const setupMobile = () => {
    setExpandedMobile(false)
    setIsMobile(true)
  }

  const setupDesktop = () => {
    setIsMobile(false)
    setExpandedMobile(false)
  }

  const handleExpand = () => {
    setExpandedMobile(expanded => !expanded)
  }

  const handleClick = () => {
    setColorMode(colorMode === "light" ? "dark" : "light")
  }

  return (
    <footer sx={{ variant: "styles.footer" }}>
      <div sx={{ variant: "styles.footerContent" }}>
        {/* Theme button desktop */}
        {!isMobile && (
          <span
            sx={{
              display: ["none", "initial", "initial", "initial"],
              width: "80px",
            }}
          >
            {colorMode === "light" ? (
              <span title="Go Dark">
                <Moon
                  sx={{ variant: "styles.themeIconButton" }}
                  onClick={() => handleClick()}
                />
              </span>
            ) : (
              <span title="Go Light">
                <Sun
                  sx={{ variant: "styles.themeIconButton" }}
                  onClick={() => handleClick()}
                />
              </span>
            )}
          </span>
        )}

        {/* Company & Copyright */}
        <span
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            fontSize: ["12px", "14px", "16px", "16px"],
            // flexGrow: [1, 0, 0, 0],
          }}
        >
          <span sx={{ whiteSpace: "nowrap" }}>
            Copyright © {new Date().getFullYear()},
          </span>{" "}
          <a
            sx={{
              variant: "styles.footerLink",
            }}
            href={companyUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {companyName}
          </a>
          {/* Expand button mobile */}
          {isMobile && (
            <span
              sx={{
                display: ["inherit", "none", "none", "none"],
              }}
            >
              {expandedMobile ? (
                <ChevronDown onClick={() => handleExpand()} />
              ) : (
                <ChevronUp onClick={() => handleExpand()} />
              )}
            </span>
          )}
        </span>

        {/* {expandedMobile && ( */}
        {(!isMobile || (isMobile && expandedMobile)) && (
          <span
            sx={{
              pt: [2, "initial", "initial", "initial"],
              minWidth: ["250px", "initial", "initial", "initial"],
              display: ["flex", "initial", "initial", "initial"],
              flexDirection: ["row", "initial", "initial", "initial"],
              justifyContent: ["space-around", "initial", "initial", "initial"],
            }}
          >
            {isMobile && (
              <span sx={{ display: ["initial", "none", "none", "none"] }}>
                {colorMode === "light" ? (
                  <span title="Go Dark">
                    <Moon
                      sx={{ variant: "styles.themeIconButton" }}
                      onClick={() => handleClick()}
                    />
                  </span>
                ) : (
                  <span title="Go Light">
                    <Sun
                      sx={{ variant: "styles.themeIconButton" }}
                      onClick={() => handleClick()}
                    />
                  </span>
                )}
              </span>
            )}
            <img
              src={colorMode === "light" ? logo : logoWhite}
              alt="Logo"
              // width="80"
              sx={{
                verticalAlign: "middle",
                width: [60, 80, 80, 80],
              }}
            />
          </span>
        )}
      </div>
    </footer>
  )
}

export default Footer
