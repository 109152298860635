export const MAPBOX_TOKEN = process.env.GATSBY_MAPBOX_TOKEN

export const MAPBOX_LIGHT_MAP = "mapbox://styles/mapbox/light-v10"
export const MAPBOX_DARK_MAP = "mapbox://styles/mapbox/dark-v10"

export const DARK_MINIMALIST =
  "mapbox://styles/victordelval-geodb/ck5pe36il2faz1invun3c720c"
export const LIGHT_MINIMALIST =
  "mapbox://styles/victordelval-geodb/ck5pezgyf2f0b1imrk5fme1n6"

export const DARK_MINIMALIST_3D_BUILDINGS =
  "mapbox://styles/victordelval-geodb/ck5ympu1304cu1ipcugbr96pq"
export const LIGHT_MINIMALIST_3D_BUILDINGS =
  "mapbox://styles/victordelval-geodb/ck5yn4eqi1i651ipht2ecd5ow"

export const INITIAL_VIEW_STATE = {
  // width: "100%",
  // height: "calc(100vh - 126px)", // minus header + footer
  latitude: 40.418889,
  longitude: -3.691944,
  zoom: 2,
}
