/** @jsx jsx */
import { jsx, useColorMode } from "theme-ui"
import React from "react"
import "./shadow-animation.css"

export default function ShadowAnimation({ dashboard }) {
  const [colorMode] = useColorMode()
  return (
    <div
      className="shadowAnimationWrapper"
      sx={{
        position: "absolute",
        width: "100%",
        height: dashboard ? "calc(100vh - 131px)" : "100%",
        left: 0,
        zIndex: 900,
        backgroundColor: colorMode === "light" ? "white" : "black",
      }}
    ></div>
  )
}
